import React, { useState, useEffect } from 'react';
import { navigate, Link, graphql } from 'gatsby';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Box } from '@mui/material';
import Layout from '../components/layout/layout';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import { signOutFromFirebase, getUserID } from '../hooks/useFirebase';
import { SnackBar } from '../components/snackbar';
import { useClaimStatus, usePaymentMethod, useSnackbar } from '../store';
import {
  paymentTypeValues,
  claimStatusTitlesResultsPage,
  claimResultValues,
} from '../constants';

const claimStatusTexts = {
  confirmed: {
    title: 'You have successfully submitted a claim.',
    paymentMethod: (_paymentMethod) => (
      <>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '22px',
            marginTop: '32px',
          }}
        >
          Payment Method
        </Typography>
        <Typography
          sx={{
            marginTop: '16px',
            mb: 1,
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '24px',
            color: '#221F20',
          }}
        >
          {_paymentMethod}
        </Typography>
      </>
    ),
  },
  rejected: {
    title: '',
    paymentMethod: (_paymentMethod) => null,
    //  description: 'The below findings impacted this determination. If you have questions or to dispute this determination please contact us and provide this Submission ID.',
    // reason: ['Household Limit', 'Claim History', 'Multiple Names', 'Max Units', 'Claim Source'],
  },
};

const Results = ({ data }) => {
  const { message, claimID, subscribeToClaimStatus } = useClaimStatus();
  const { paymentMethod, fetchPaymentMethod } = usePaymentMethod();
  const userID = getUserID();
  const { caseID } = data.template;
  const claimStatus =
    message === 'Claim rejected'
      ? claimResultValues.rejected
      : message === 'Claim approved'
      ? claimResultValues.confirmed
      : '';
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const { clearClaimStatus } = useClaimStatus();
  const { clearPaymentMethod } = usePaymentMethod();
  const signOutAndRedirectToHome = async () => {
    setLoading(true);
    try {
      await signOutFromFirebase();
      navigate('/');
      clearClaimStatus();
      clearPaymentMethod();
    } catch (e) {
      openSnackbar(
        'Experiencing an unexpected error. Please try again.',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!message && userID) {
      return subscribeToClaimStatus(caseID, userID);
    }
  }, [claimStatus, userID]);

  useEffect(() => {
    if (
      !paymentMethod &&
      claimID &&
      claimStatus !== claimResultValues.rejected
    ) {
      fetchPaymentMethod(caseID, claimID);
    }
  }, [paymentMethod, claimID]);

  const showCorrectPaymentMethodNameHandler = (_paymentMethod) => {
    const paymentTypeValue = paymentTypeValues.filter(
      (_paymentType) => _paymentType.value === _paymentMethod
    );

    return paymentTypeValue.length > 0 ? paymentTypeValue[0].label : '';
  };

  return (
    <>
      <LoadingOverlay open={loading} />
      <Layout style={{ display: 'flex', alignItems: 'center' }}>
        <SnackBar>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '56px',
                  textAlign: 'center',
                  lineHeight: '56px',
                  letterSpacing: '-1px',
                  maxWidth: 600,
                }}
              >
                {claimStatusTitlesResultsPage[claimStatus]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 500,
                  fontSize: '24px',
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {claimStatusTexts[claimStatus]?.title}
              </Typography>
            </Grid>
            <Box mb={4} mt={4}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                Please keep your Claim Submission ID for your records.
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                You will need to reference this ID on any future correspondence.
              </Typography>
            </Box>
            <Grid item xs={12} sx={{ mb: 4 }}>
              <Paper
                elevation={0}
                sx={{
                  border: '1px solid #D6D5D3',
                  p: '24px',
                  backgroundColor: '#fcfaf8',
                  maxWidth: 490,
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent={
                    claimStatus === claimResultValues.rejected
                      ? 'center'
                      : 'left'
                  }
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '22px',
                    }}
                  >
                    Claim Submission ID
                  </Typography>
                  <IconButton
                    onClick={() => navigator.clipboard.writeText(claimID)}
                    sx={{
                      px: 0,
                      ':hover': { backgroundColor: 'transparent' },
                      marginLeft: '10px',
                    }}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Grid>
                <Typography
                  justifyContent={
                    claimStatus === claimResultValues.rejected
                      ? 'center'
                      : 'left'
                  }
                  sx={[
                    {
                      marginTop: '16px',
                      mb: 1,
                      fontWeight: 500,
                      fontSize: '24px',
                      lineHeight: '24px',
                      color: '#221F20',
                    },
                    claimStatus === claimResultValues.rejected && {
                      textAlign: 'center',
                    },
                  ]}
                >
                  {claimID}
                </Typography>
                {claimStatusTexts[claimStatus]?.paymentMethod(
                  showCorrectPaymentMethodNameHandler(paymentMethod)
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sx={{ mb: 4 }}>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  maxWidth: 580,
                  marginBottom: 0,
                }}
              >
                The administrator may contact you with updates regarding your
                submission. You may also
                <Link
                  style={{
                    color: '#3E8682',
                    textDecoration: 'underline',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 700,
                  }}
                  to="/contact"
                >
                  contact the administrator
                </Link>
                with any questions.
              </p>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="button"
                variant="contained"
                sx={{ color: 'white', display: 'block' }}
                disableElevation
                onClick={signOutAndRedirectToHome}
              >
                Start a New Claim
              </Button>
            </Grid>
          </Grid>
        </SnackBar>
      </Layout>
    </>
  );
};

export default Results;

export const Head = ({ data }) => {
  const { template: { caseName = '' } } = data;

  return (
    <title>Results | {caseName}</title>
  )
}


export const query = graphql`
  query ResultsPage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseName
      caseID
    }
  }
`;
